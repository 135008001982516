import {Link} from "react-router-dom";
import React from "react";
import { HOME_ROUTE } from "../../utils/router";

const PaymentFailed = ({onClick}) => {
  return (
    <div className="bg-white p-6 md:mx-auto">
      <svg
        viewBox="0 0 24 24"
        className="text-red-600 w-16 h-16 mx-auto my-6"
      >
        <path
          fill="currentColor"
          d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm8.485,15.314-8.485,8.485L3.515,15.314A9.932,9.932,0,0,0,2,20a10,10,0,1,0,20,0A9.932,9.932,0,0,0,20,17.314ZM18.879,8.879,17.121,7.121,12,12.243,6.879,7.121,5.121,8.879,10.243,14,5.121,19.121l1.758,1.758L12,15.757l5.121,5.121,1.758-1.758L14.757,14Z"
        ></path>
      </svg>
      <div className="text-center">
        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
          Payment Failed
        </h3>
        <p className="text-gray-600 my-2">
          We're sorry, your payment was not successful.
        </p>
        <p>Please check your payment details and try again.</p>
        <div className="py-10 text-center">
          <button
            onClick={onClick}
            className="px-12 bg-accent-500 hover:bg-accent-800 text-white font-semibold py-3 mr-4 rounded-full inline-block"
          >
            RETRY PAYMENT
          </button>
          <Link
            href={HOME_ROUTE}
            className="px-12 bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-3 rounded-full inline-block"
          >
            GO BACK
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;

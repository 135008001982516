import React, { useState, useEffect } from "react";
import apiRequest from "../utils/apiRequest";
import { Link } from "react-router-dom";
import Input from "../components/utils/Input";
import { toast } from "react-hot-toast";
import PaymentImage from "../assets/payment/payment.svg";
import UserAuthWrapper from "../components/middleware/UserAuthWrapper";
import { deleteFromCart, fetchCartItem } from "../redux/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import PaymentSuccess from "../components/payment/PaymentSuccess";
import PaymentFailed from "../components/payment/PaymentFailed";
import { Helmet } from "react-helmet";
import {
  createSlug,
  formatAmountToINR,
  getAssetUriWithSize,
} from "../utils/helper";
import { PRODUCT_DETAIL_ROUTE } from "../utils/router";
import { useFormik } from "formik";
import AddressCard from "../components/address/AddressCard";
import { checkOutSchema } from "../validation/checkOutSchema";
import CartEmpty from "../components/cart/CartEmpty";
const Checkout = () => {
  const carts = useSelector((state) => state.headerRedux.carts);
  const selectedAddress = useSelector(
    (state) => state.orderRedux.selectedAddress
  );
  const dispatch = useDispatch();
  const [userAddress, setUserAddress] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(null);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const getUserSavedAddress = async () => {
    await apiRequest
      .getUserSavedAddress()
      .then((res) => {
        setUserAddress(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCartItem = async (variationId, productId) => {
    dispatch(
      deleteFromCart({ product_id: productId, variation_id: variationId })
    );
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      postcode: "",
      address: "",
      locality: "",
      isSaved: null,
      file: null,
    },
    validationSchema: checkOutSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const calculatedAmount = calculateTotal() + calculateTax();
      setLoading("Processing Order...");
      const formData = new FormData();
      Object.entries(
        Object.assign({}, values, {
          amount: calculatedAmount,
        })
      ).forEach(([key, value]) => {
        formData.append(key, value);
      });
      await apiRequest
        .createPaymentOrder(formData)
        .then((res) => {
          if (res.data.response) {
            setLoading(false);
            toast.success(res.data.msg);
            dispatch(fetchCartItem());
            setIsPaymentSuccess(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && err.response.status === 400) {
            setValidationError(err.response.data.errors);
          }
          console.log(err);
        });
    },
  });

  const calculateTotal = () => {
    let total = 0;
    for (const key in carts) {
      if (carts.hasOwnProperty(key)) {
        const item = carts[key];
        if (
          item &&
          item.variation &&
          item.variation.active_price &&
          item.variation.active_price.price
        ) {
          let productPrice = item.variation.active_price.price * item.quantity;
          if (
            item.variation.active_price &&
            item.variation.active_price.discount > 0
          ) {
            let discount =
              (productPrice * item.variation.active_price.discount) / 100;
            total += productPrice - discount;
          } else {
            total += productPrice;
          }
        }
      }
    }
    return total;
  };
  useEffect(() => {
    if (selectedAddress) {
      formik.setValues({
        ...selectedAddress,
        isSaved: "Save",
      });
    }
  }, [selectedAddress]);
  const calculateDiscount = () => {
    let discount = 0;
    for (const key in carts) {
      if (carts.hasOwnProperty(key)) {
        const item = carts[key];
        if (
          item &&
          item.variation &&
          item.variation.active_price &&
          item.variation.active_price.price
        ) {
          let productPrice = item.variation.active_price.price * item.quantity;
          if (
            item.variation.active_price &&
            item.variation.active_price.discount > 0
          ) {
            discount +=
              (productPrice * item.variation.active_price.discount) / 100;
          }
        }
      }
    }
    return discount;
  };

  const calculateTax = () => {
    let total = 0;
    for (const key in carts) {
      if (carts.hasOwnProperty(key)) {
        const item = carts[key];
        if (
          item &&
          item.variation &&
          item.variation.active_price &&
          item.variation.active_price.price
        ) {
          const taxPercentage = item.variation.product.tax || 0;
          const taxAmount =
            (item.variation.active_price.price * taxPercentage) / 100;
          total += taxAmount;
        }
      }
    }
    return total;
  };
  useEffect(() => {
    getUserSavedAddress();
  }, []);

  if (isPaymentSuccess)
    return (
      <div>
        <Helmet>
          <title>Order Success - Boundry Wala</title>
        </Helmet>
        <PaymentSuccess />
      </div>
    );

  if (isPaymentFailed)
    return (
      <div>
        <PaymentFailed onClick={() => setIsPaymentFailed(false)} />
      </div>
    );
  if (carts.length === 0)
    return (
      <>
        {" "}
        <Helmet>
          <title>Checkout - Boundry Wala</title>
        </Helmet>
        <CartEmpty />
      </>
    );

  return (
    <UserAuthWrapper>
      <Helmet>
        <title>Checkout - Boundry Wala</title>
      </Helmet>
      <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div className="px-4 pt-8">
          <p className="text-xl font-medium">Order Summary</p>
          <p className="text-gray-400 text-base">
            Check your items. And select a suitable shipping method.
          </p>
          <div className="mt-3 space-y-3 rounded-lg border  px-2 py-4 sm:px-6  bg-white">
            <ul className="-my-6 divide-y divide-gray-200">
              {carts.map((item, index) => {
                return (
                  <li key={index} className="flex py-6 ">
                    <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                      <img
                        src={getAssetUriWithSize(
                          "variations",
                          item.variation.active_image
                            ? item.variation.active_image.image
                            : 404,
                          300,
                          300
                        )}
                        alt="Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch."
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="ml-4 flex flex-1 flex-col">
                      <div>
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <h3>
                            <Link
                              href={PRODUCT_DETAIL_ROUTE(
                                item.variation.id,
                                encodeURIComponent(
                                  createSlug(item.variation.title)
                                )
                              )}
                            >
                              {item.variation.title}
                            </Link>
                          </h3>
                          <p className="ml-4">
                            {formatAmountToINR(
                              item.variation.active_price.price * item.quantity
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 items-end justify-between text-sm">
                        <Quantity
                          id={item.id}
                          unit={item.variation?.product?.unit?.name}
                          quantity={item.quantity}
                          price={item.variation.active_price.price}
                        />
                        <div className="flex">
                          <button
                            type="button"
                            onClick={() => {
                              deleteCartItem(
                                item.variation.id,
                                item.variation.product_id
                              );
                            }}
                            className="font-medium text-primary-600 hover:text-primary-500"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <p className="mt-3 text-lg font-medium">Payment Method</p>
          <form className="mt-3 grid gap-3 mb-5">
            <div className="relative">
              <input
                className="peer hidden"
                id="radio_1"
                type="radio"
                checked={true}
                disabled={true}
                name="payment_method"
              />
              <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white" />
              <label
                className="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                htmlFor="radio_1"
              >
                <img
                  alt="payment"
                  className="w-14 object-contain"
                  src={PaymentImage}
                  width={300}
                  height={300}
                />
                <div className="ml-5">
                  <span className="mt-2 font-semibold">Pay Letter</span>
                  <p className="text-slate-500 text-sm leading-6">
                    You need to make the payment offline by visiting our office.
                  </p>
                </div>
              </label>
            </div>
          </form>
        </div>
        <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p className="text-xl font-medium">Shipping Address</p>
          <p className="text-gray-400 text-base">
            Complete your order by providing your shipping address details.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <Input
                    type={"text"}
                    label={"First Name"}
                    name={"first_name"}
                    placeholder={"Enter Your First Name"}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.first_name}
                    serverError={validationError.first_name}
                    touched={formik.touched.first_name}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    label={"Last Name"}
                    name={"last_name"}
                    placeholder={"Enter Your First Name"}
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.last_name}
                    serverError={validationError.last_name}
                    touched={formik.touched.last_name}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    label={"mobile"}
                    name={"mobile"}
                    placeholder={"Enter Your Mobile No."}
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.mobile}
                    serverError={validationError.mobile}
                    touched={formik.touched.mobile}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    name={"email"}
                    label={"email"}
                    placeholder={"Enter Your Email Address"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email}
                    serverError={validationError.email}
                    touched={formik.touched.email}
                  />
                </div>

                <div>
                  <Input
                    type={"text"}
                    name={"address"}
                    placeholder={"Enter Your Address"}
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    label={"Address"}
                    onBlur={formik.handleBlur}
                    error={formik.errors.address}
                    serverError={validationError.address}
                    touched={formik.touched.address}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    name={"locality"}
                    label={"locality"}
                    placeholder={"Enter Your locality"}
                    value={formik.values.locality}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.locality}
                    serverError={validationError.locality}
                    touched={formik.touched.locality}
                  />
                </div>
              </div>
              <div>
                <Input
                  type={"text"}
                  name={"postcode"}
                  label={"postcode"}
                  placeholder={"Enter Your postcode"}
                  value={formik.values.postcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.postcode}
                  serverError={validationError.postcode}
                  touched={formik.touched.postcode}
                />
              </div>

              <div className="flex items-center my-4">
                <input
                  id="save-address"
                  type="checkbox"
                  name="isSaved"
                  onBlur={formik.handleBlur}
                  checked={formik.values.isSaved === "Save"}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue(
                      "isSaved",
                      e.target.checked ? "Save" : null
                    );
                  }}
                  defaultValue=""
                  className="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 rounded focus:ring-accent-500 dark:focus:ring-accent-500 "
                />
                <label
                  htmlFor="save-address"
                  className="ms-2 text-base font-medium text-gray-900 dark:text-gray-300"
                >
                  Save Address
                </label>
              </div>
              <div className="grid mt-3 grid-cols-2 gap-3">
                <AddressCard address={userAddress} />
              </div>
              <div className="mt-6 border-t border-b py-2">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">Subtotal:</p>
                  <p className="font-semibold text-gray-900">
                    {formatAmountToINR(calculateTotal())}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">Tax:</p>
                  <p className="font-semibold text-gray-900">
                    {formatAmountToINR(calculateTax())}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">
                    Discount Applied:
                  </p>
                  <p className="font-semibold text-gray-900">
                    {formatAmountToINR(calculateDiscount())}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">Shipping:</p>
                  <p className="font-semibold text-gray-900">
                    {formatAmountToINR(0)}
                  </p>
                </div>
              </div>
              <div className="mt-6 flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Total:</p>
                <p className="text-2xl font-semibold text-gray-900">
                  {formatAmountToINR(calculateTotal() + calculateTax())}
                </p>
              </div>
            </div>
            {loading ? (
              <button
                type="submit"
                className="mt-4 mb-8 w-full rounded-md bg-accent-500 px-6 py-3 font-medium text-white"
              >
                <div
                  role="status"
                  className=" flex w-full items-center justify-center"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="ml-3">{loading}</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="mt-4 mb-8 w-full rounded-md bg-accent-500 px-6 py-3 font-medium text-white"
              >
                Place Order
              </button>
            )}
          </form>
        </div>
      </div>
    </UserAuthWrapper>
  );
};

export default Checkout;

const Quantity = ({ id, quantity, unit, price }) => {
  const [noOfQuanatity, setQuantity] = useState(quantity);
  const dispatch = useDispatch();
  const handleQuantity = async (event) => {
    if (event.target.value > 0) {
      setQuantity(event.target.value);
      try {
        const response = await apiRequest.cartIncrementDecrement(id, {
          quantity: event.target.value,
        });
        if (response.data.response) {
          toast.success("Product Quantity Updated");
          dispatch(fetchCartItem());
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setQuantity(event.target.value);
    }
  };
  return (
    <>
      {unit !== null ? (
        <div className="flex items-center gap-3">
          <input
            type="text"
            value={noOfQuanatity}
            onChange={handleQuantity}
            className="w-12 text-center border border-gray-300 rounded-md py-1 px-2 bg-white outline-none focus:ring-2 focus:ring-blue-400"
          />

          <p className="text-gray-500">
            * {price} / {unit}
          </p>
        </div>
      ) : (
        <div className="flex items-center gap-3">
          <p className="text-gray-500">Qty</p>
          <input
            type="text"
            value={noOfQuanatity}
            onChange={handleQuantity}
            className="w-12 text-center border border-gray-300 rounded-md py-1 px-2 bg-white outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
      )}
    </>
  );
};

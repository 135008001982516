import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Product from "../components/product/Product";
import UserAuthWrapper from "../components/middleware/UserAuthWrapper";

export default function Wishlist() {
  const { wishlists } = useSelector(({ headerRedux }) => headerRedux);
  const products = wishlists.map((wishlist) => wishlist.variation);

  return (
    <div>
      <Helmet>
        <title>Wishlist - Boundry Wala</title>
      </Helmet>
      <UserAuthWrapper>
        <div className="mt-6">
          <Product products={products} />
        </div>
      </UserAuthWrapper>
    </div>
  );
}

import React from "react";
import cn from "classnames";
import { getAssetUri } from "../../utils/helper";

export default function HeroBannerCard({
  banner,
  className = "py-20 xy:pt-24",
  variant,
}) {
  const selectedImage = banner.banner;

  return (
    <div
      className={cn(
        "w-full bg-no-repeat bg-cover bg-center flex items-center rounded relative",
        {
          "min-h-[320px] md:min-h-[367px]": variant === "slider",
        },
        className
      )}
      style={{
        backgroundImage: `url(${getAssetUri("banner", selectedImage)})`,
      }}
    >
      <div
        className={cn(
          "sm:absolute inset-0 m-[15px] md:mt-[30px] xl:mt-[50px] w-full",
          {
            "mx-auto max-w-[480px] md:max-w-[580px] xl:max-w-[700px]":
              variant === "slider",
            "mx-auto max-w-[480px] md:max-w-[580px] xl:max-w-[600px]":
              variant === "antique",
            "lg:px-20 max-w-[480px] md:max-w-[580px] xl:max-w-[700px]":
              variant === "slider-4",
          }
        )}
      >
        <div
          className={cn("text-left ", {
            "md:w-8/12 lg:w-6/12": variant === "slider",
            "text-left": variant === "slider-4",
          })}
        >
          {banner.description && (
            <p
              className={cn("text-[12px] leading-7 uppercase font-bold ", {
                "text-white ": variant === "default",
                "text-white": variant === "slider",
                "": variant === "antique",
              })}
            >
              {banner.description}
            </p>
          )}
          {banner.title && (
            <h2
              style={{
                textShadow: `0 0 5px #f00, 0 0 10px #f00, 0 0 20px #f00, 0 0 40px #f00, 0 0 80px #f00`,
              }}
              className={cn(
                "text-4xl md:text-4xl font-semibold mt-2 leading-8",
                {
                  "xl:text-5xl 2xl:text-[36px] text-white leading-snug md:leading-tight xl:leading-[1.3em] mb-3 md:mb-4 xl:mb-3 ":
                    variant !== "antique",
                  "text-white 2xl:text-[36px]": variant === "default",
                  "text-white 2xl:text-[36px] ": variant === "antique",
                }
              )}
            >
              {banner.title && banner.title}
            </h2>
          )}

          {banner.btnText && (
            <a
              href={banner.btnUrl}
              className={cn(
                " h-[44px] mt-5 md:mt-12 text-base inline-flex items-center justify-center transition duration-300 rounded px-10 py-2 font-semibold ",
                {
                  "text-fill-base bg-white hover:text-white hover:bg-primary ":
                    variant !== "antique",
                  "text-brand-light bg-brand hover:text-white hover:bg-white ":
                    variant === "antique",
                }
              )}
            >
              {banner.btnText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

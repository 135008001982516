import React from "react";
import HeroSliderBlock from "../swiper/HeroSliderBlock";
import BannerGrid from "../../components/banner/BannerGrid";
import Banner1 from "../../assets/banner/banner-1.jpg";
import Banner2 from "../../assets/banner/banner-2.jpg";
import AboutHome from "../../assets/about/about-home.jpg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ABOUT_ROUTE } from "../../utils/router";
const Hero = () => {
  const { sliders } = useSelector(({ headerRedux }) => headerRedux);
  const banners = [
    {
      image: Banner1,
      id: 1,
    },
    {
      id: 2,
      image: Banner2,
    },
  ];
  return (
    <>
      <div className="mx-auto px-2 md:px-2 3xl:px-0 ">
        <div className="grid xl:gap-[5px] grid-cols-1 xl:grid-cols-12">
          <HeroSliderBlock
            data={sliders}
            variant="slider"
            className={`xl:col-span-8 mb-5 xl:mb-12`}
            contentClassName="p-7 sm:py-18 xl:py-16 sm:px-16 xl:px-24 md:h-[270px] xl:h-[375px] rounded"
          />
          <BannerGrid
            grid={1}
            images={banners}
            girdClassName="xl:gap-[5px]"
            className="mb-5 xl:col-span-4 xl:mb-12"
          />
        </div>
      </div>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
        <div className=" py-3  mb-3 rounded bg-white px-5">
          <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
            About Us
          </h3>
        </div>
        <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2 relative">
          <div className="mx-4 max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
            <p className=" text-gray-500 md:mt-4 ">
              Founded in the year 2015, Shiv Green Cement Products is the most
              trusted manufacturer and supplier from dealing in the
              revolutionary industrial walls. We aim to become the most trusted
              firm providing our customers with products that have high
              performance. Based in Patna, Bihar we manufacture and produce
              products like RCC Cover blocks, RCC poles, PSC poles, RCC slabs
              and much more. Our products are designed to appear as if they are
              made of crafted wood, natural stone or weathered brick and they do
              not get damaged by any collusion. Our products are inexpensive and
              easy to install.
            </p>
            <p className="hidden text-gray-500 md:mt-4 md:block">
              We have engineered our products professionals which interlock
              during the process of installation. We provide our customers with
              high-quality products and services to assure that there is
              efficient execution of your project. Our company has done intense
              research in creating these products which are economical and have
              great potential. We are committed to forming a long term
              relationship with our customers by serving them with quality
              products which meets their needs and specifications.
            </p>
            <div className="mt-4 md:mt-8">
              <Link
                to={ABOUT_ROUTE}
                className="mt-8 inline-block rounded bg-accent-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-accent-700 focus:outline-none focus:ring focus:ring-primary-400"
              >
                About Us
              </Link>
            </div>
          </div>
          <img
            alt="about"
            src={AboutHome}
            className="h-56 w-full object-cover sm:h-full"
          />
        </section>
      </div>
    </>
  );
};

export default Hero;

import React, { useState, useEffect, useRef } from "react";
import { FaCartArrowDown } from "react-icons/fa6";
import { AiOutlineHeart, AiOutlineLogin } from "react-icons/ai";
import { MdAddIcCall } from "react-icons/md";
import { GiPathDistance } from "react-icons/gi";
import NavLinks, { MobileNav } from "./NavLinks";
import { IoCloseOutline } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import {
  logoutUser,
  setopenCart,
  setopenNav,
  getHomeData,
  setLoginOpen,
} from "../../redux/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { MdAccountCircle } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import { FaSearch } from "react-icons/fa";
import {
  WISHLIST_ROUTE,
  SHOP_ROUTE,
  HOME_ROUTE,
  CONTACT_ROUTE,
  ABOUT_ROUTE,
  SEARCH_ROUTE,
  MY_ACCOUNT_ROUTE,
} from "../../utils/router";
import Cart from "./Cart";
import { Avatar, Badge, Button, Menu } from "@mui/material";
import authManager from "../../utils/authManager";
import { stringAvatar } from "../../utils/helper";
import apiRequest from "../../utils/apiRequest";
import { BiUserCircle } from "react-icons/bi";
const Header = () => {
  const linkHome = [{ name: "Home", link: HOME_ROUTE }];
  const [links, setLinks] = useState();
  const { openCart, openNav, carts, homeDataloaded, profile } = useSelector(
    ({ headerRedux }) => headerRedux
  );
  const linkOther = [
    { name: "About Us", link: ABOUT_ROUTE },
    { name: "Contact Us", link: CONTACT_ROUTE },
  ];
  const categories = useSelector((state) => state.headerRedux.categories);
  const dispatch = useDispatch();
  const toggleCart = () => {
    dispatch(setopenCart(!openCart));
  };
  const closeMobileNav = () => {
    dispatch(setopenNav(false));
  };
  const handleCloseCart = () => {
    if (openCart) {
      dispatch(setopenCart(false));
    }
  };
  const openLogin = () => {
    dispatch(setLoginOpen(true));
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      zoom: 0.8,
      top: 0,
      background: "#043494",
      border: `2px solid #043494`,
      padding: "0 4px",
    },
  }));

  const toggleOpenCart = () => {
    dispatch(setopenCart(!openCart));
  };
  const handleLogoutUser = () => {
    dispatch(logoutUser());
  };
  const openMobileNav = () => {
    dispatch(setopenNav(true));
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSearchActive, setIsSearchActive] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchSuggestion, setSearchSuggestion] = useState([]);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }
  useEffect(() => {
    if (dispatch && !homeDataloaded) {
      dispatch(getHomeData());
    }
  }, [dispatch]);
  useEffect(() => {
    if (categories.length > 0) {
      const categoryLinks = transformCategoriesToNavLinks(categories);
      setLinks([...linkHome, ...categoryLinks, ...linkOther]);
    }
  }, [categories]);

  const handleSearchSelect = async (e) => {
    setSearchValue(e.target.innerText);
    setIsSearchActive(false);

    if (searchTimerRef.current) {
      clearTimeout(searchTimerRef.current);
    }
    searchTimerRef.current = setTimeout(async () => {
      const queryParams = Object.entries({
        limit: 5,
        offset: 0,
        searchQuery: e.target.value,
      })
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return `${key}=${value.join(",")}`;
          } else {
            return `${key}=${value}`;
          }
        })
        .join("&");

      try {
        const response = await apiRequest.getProduct(queryParams);
        const titles = response.data.data.map((product) => product.title);
        setSearchSuggestion(titles);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    }, 300);
  };
  const searchTimerRef = useRef(null);

  const handleSearchChange = async (e) => {
    setSearchValue(e.target.value);
    if (searchTimerRef.current) {
      clearTimeout(searchTimerRef.current);
    }
    searchTimerRef.current = setTimeout(async () => {
      const queryParams = Object.entries({
        limit: 5,
        offset: 0,
        searchQuery: e.target.value,
      })
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return `${key}=${value.join(",")}`;
          } else {
            return `${key}=${value}`;
          }
        })
        .join("&");

      try {
        const response = await apiRequest.getProduct(queryParams);
        const titles = response.data.data.map((product) => product.title);
        setSearchSuggestion(titles);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    }, 300);
  };

  return (
    <div>
      <nav className="bg-primary-500 py-2 px-5 flex justify-between items-center">
        <div className="flex items-center">
          <MdAddIcCall className="text-white text-x2s md:text-xs  mr-1" />
          <span className="text-white text-x2s md:text-xs  uppercase">
            +91-7061777111
          </span>
        </div>
        <div className="flex items-center">
          <div className="flex items-center">
            <GiPathDistance className="text-white text-x2s md:text-xs  mr-1" />
            <span className="text-white text-x2s md:text-xs  uppercase">
              Welcome to Shivgreen India!
            </span>
          </div>
        </div>
      </nav>
      <nav className="flex sm:static bg-white relative xxs:flex-row flex-col items-center grid-cols-2 justify-between shadow sm:py-0  pb-16 pt-5 px-4">
        <Link to={HOME_ROUTE}>
          <div className="py-2 w-[100px] md:w-[150px]">
            <img
              src={Logo}
              alt="logo"
              className="w-full h-full object-center object-cover lg:w-full lg:h-full"
            />
          </div>
        </Link>
        <ClickAwayListener onClickAway={() => setIsSearchActive(false)}>
          <div className="px-3 pt-2 sm:px-0 sm:relative absolute sm:bottom-0 bottom-4 left-0  w-full sm:max-w-[400px]">
            <div className="relative lg:max-w-[600px] w-full">
              <input
                type="text"
                autoComplete="off"
                value={searchValue}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchActive(true)}
                className="bg-slate-100 w-full h-12 px-3 pr-16  rounded-full focus:border-primary-500 border-[1px] focus:outline-none text-sm"
                name="search"
                placeholder="Search a Product"
              />
              <span className="absolute top-0 right-[10px] border-l pl-4">
                <Link to={SEARCH_ROUTE(searchValue)}>
                  <FaSearch className="absolute right-5 text-2xl top-3 hover:text-accent-500" />
                </Link>
              </span>
              <div
                className={`absolute top-[50px] left-0 w-full bg-white z-10 searchShadow ${
                  isSearchActive ? "block" : "hidden"
                }`}
              >
                <ul className="divide-y divide-gray-200">
                  {searchSuggestion &&
                    searchSuggestion.map((item, index) => (
                      <a
                        key={index}
                        onClick={handleSearchSelect}
                        className="cursor-pointer"
                      >
                        <li className="border-b border-gray-200 hover:bg-slate-200 transition-colors duration-300 block py-2 px-6">
                          <p className="text-sm text-gray-600">{item}</p>
                        </li>
                      </a>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </ClickAwayListener>
        <ul className="flex justify-end flex-row items-center gap-5 ml-2 p-0">
          <li>
            {authManager.hasAccessToken() && profile ? (
              <>
                <Avatar
                  {...stringAvatar(profile.name)}
                  onClick={handleClick}
                  onMouseOver={handleClick}
                />
                <Menu
                  sx={{ top: "20px" }}
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className="m-0"
                  MenuListProps={{ onMouseLeave: handleClose }}
                >
                  <ul className="list-none m-0 p-0 w-[150px]">
                    <li className="text-left w-full m-0">
                      <Link to={MY_ACCOUNT_ROUTE}>
                        <Button className="w-full text-left text-xs justify-start text-slate-500 px-[10px] py-[5px] hover:bg-[#f1f1f1]">
                          <MdAccountCircle className="opacity-75 mr-[8px]" /> My
                          Account
                        </Button>
                      </Link>
                    </li>
                    <li className="text-left w-full m-0">
                      <Link to={WISHLIST_ROUTE}>
                        <Button className="w-full text-left text-xs justify-start text-slate-500 px-[10px] py-[5px] hover:bg-[#f1f1f1]">
                          <AiOutlineHeart className="opacity-75 mr-[8px]" /> My
                          WishList
                        </Button>
                      </Link>
                    </li>
                    <li className="text-left w-full m-0">
                      <Button
                        onClick={handleLogoutUser}
                        className="w-full text-left text-xs justify-start text-slate-500 px-[10px] py-[5px] hover:bg-[#f1f1f1]"
                      >
                        <AiOutlineLogout className="opacity-75 mr-[8px]" /> Sign
                        Out
                      </Button>
                    </li>
                  </ul>
                </Menu>
              </>
            ) : (
              <Button
                onClick={openLogin}
                style={{ color: "#000", fontSize: "10px" }}
                startIcon={<AiOutlineLogin />}
              >
                <p className="lg:block hidden">Sign In</p>
              </Button>
            )}
          </li>

          <ClickAwayListener onClickAway={handleCloseCart}>
            <div>
              <li className="m-0 p-0" onClick={toggleOpenCart}>
                <Button
                  sx={{ padding: "0px 10px" }}
                  style={{ color: "#000", fontSize: "10px" }}
                  startIcon={
                    <div>
                      <StyledBadge
                        badgeContent={carts.length}
                        max={99}
                        color="secondary"
                      >
                        <FaCartArrowDown />
                      </StyledBadge>
                    </div>
                  }
                >
                  <p className="lg:block hidden">My Cart</p>
                </Button>
              </li>
              <div
                className={`fixed   ${
                  openCart ? "right-0" : "right-[-100%]"
                } top-0 z-50  inset-y-0  flex max-w-full pl-10 duration-500 h-screen`}
              >
                <Cart toggleCart={toggleCart} />
              </div>
            </div>
          </ClickAwayListener>

          <ClickAwayListener onClickAway={closeMobileNav}>
            <div>
              <div className="md:2-auto md:w-[30%] w-full md:block flex justify-end items-center">
                <div className="text-2xl lg:hidden block cursor-pointer my-7">
                  {openNav ? (
                    <IconButton onClick={closeMobileNav}>
                      <IoCloseOutline className="text-grey-500  font-bold" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={openMobileNav}>
                      <GiHamburgerMenu className="text-grey-500 font-bold" />
                    </IconButton>
                  )}
                </div>
              </div>
              <div
                style={{ zIndex: 1740 }}
                className={`fixed top-0 w-[250px] bg-slate-500 h-full z-10 lg:hidden block duration-500 ${
                  openNav ? "left-0" : "left-[-100%]"
                }`}
              >
                <div className=" h-screen flex-col justify-between border-e  bg-white">
                  <div className="px-4 py-6 h-full overflow-x-auto">
                    <span className="grid h-12 w-32 place-content-center rounded-lg bg-gray-100 text-xs text-gray-600 py-2">
                      <img src={Logo} alt="Logo" height={70} width={100} />
                    </span>
                    {links && <MobileNav links={links} />}
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </ul>
      </nav>
      <nav className="bg-white border-b-[1px] border-t-[1px] border-slate-200">
        <div className="flex items-center font-medium text-[12px] justify-between px-5">
          <ul className=" uppercase items-center font[Poppins] lg:flex hidden gap-5 py-4">
            {links && <NavLinks links={links} />}
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default Header;
const transformCategoriesToNavLinks = (categories) => {
  return categories.map((category) => {
    const children = Array.isArray(category.children) ? category.children : [];
    if (children.length > 0) {
      return {
        name: category.name,
        icon: <IoIosArrowDown />,
        submenu: children
          .map((subCategory) => ({
            name: subCategory.name,
            image: subCategory.image,
            children: subCategory.children.map((child) => ({
              name: child.name,
              link: SHOP_ROUTE(child.id, child.name),
              image: child.image,
            })),
            link: SHOP_ROUTE(subCategory.id, subCategory.name),
            icon:
              subCategory.children.length > 0 ? <IoIosArrowForward /> : <></>,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    } else {
      return {
        name: category.name,
        link: SHOP_ROUTE(category.id, category.name),
      };
    }
  });
};

import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";
const ReturnExchange = () => {
  return (
    <div>
      <Helmet>
        <title>Return & Exchange - Boundry Wala</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Return & Exchange
            </strong>
          </h1>
        </div>
      </section>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 bg-white mt-5">
        <section>
          <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-12 sm:py-12 lg:px-8 lg:py-16">
            <div className="grid grid-cols-1 gap-12 lg:grid-cols-1 lg:gap-16">
              <div className="lg:py-24">
                <h2 className="text-3xl font-bold sm:text-4xl">
                  Return & Exchange
                </h2>

                <p className="text-gray-600 text-justify mb-4">
                  At ShivGreen India, we are dedicated to providing our
                  customers with high-quality products and excellent service. If
                  you are not satisfied with your purchase, we offer an exchange
                  or return policy as outlined below.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  1. Eligibility for Exchange and Return
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  To be eligible for an exchange or return, please ensure the
                  following:
                </p>
                <ul className="list-disc pl-6 text-gray-600 mb-4">
                  <li>
                    Items must be returned within 30 days from the date of
                    delivery.
                  </li>
                  <li>
                    Products must be in their original condition, unused, and
                    with all tags and packaging intact.
                  </li>
                  <li>
                    Certain items, such as perishable goods, intimate products,
                    or personalized items, are not eligible for return.
                  </li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  2. How to Initiate a Return or Exchange
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  To initiate a return or exchange, please follow these steps:
                </p>
                <ol className="list-decimal pl-6 text-gray-600 mb-4">
                  <li>
                    Contact our customer service team via email at
                    support@shivgreenindia.com or call us at +91-XXXXXXXXXX.
                  </li>
                  <li>
                    Provide your order number and details of the item you wish
                    to return or exchange.
                  </li>
                  <li>
                    Once your return is approved, we will send you a return
                    shipping label and further instructions.
                  </li>
                </ol>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  3. Refund Process
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  After we receive and inspect the returned item, we will notify
                  you of the approval or rejection of your refund. If approved,
                  your refund will be processed within 5-7 business days, and
                  the amount will be credited to your original payment method.
                  Please note that it may take some time for your bank or credit
                  card company to process and post the refund.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  4. Shipping Costs
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  You are responsible for paying your own shipping costs for
                  returning items. Shipping costs are non-refundable. If you
                  receive a refund, the cost of return shipping will be deducted
                  from your refund amount.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  5. Exchanges for Defective or Damaged Items
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  If you receive a defective or damaged item, please contact us
                  within 7 days of receiving the item. We will arrange for a
                  replacement at no additional cost to you. You may be required
                  to send us photos of the damaged item for verification.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  6. Contact Us
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  If you have any questions or need assistance with your return
                  or exchange, please reach out to us:
                </p>
                <p className="text-gray-600 text-justify mb-4">
                  Email: info@shivgreenindia.com
                  <br />
                  Phone: +91-7061777111
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ReturnExchange;

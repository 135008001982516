import React, { useState } from "react";
import Input from "../components/utils/Input";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  HOME_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITION_ROUTE,
} from "../utils/router";
import { RegisterFormSchema } from "../validation/authFormSchema";
import { useFormik } from "formik";
import apiRequest from "../utils/apiRequest";
import { setProfileData } from "../redux/headerSlice";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import authManager from "../utils/authManager";
import RegisterImage from "../assets/auth/register.jpg";
const Register = () => {
  const [validationError, setValidationError] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    otp: "",
    confirm_password: "",
  });
  const dispatch = useDispatch();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleOtp = async () => {
    try {
      const response = await apiRequest.sendOTP({
        mobile: formik.values.mobile,
      });
      if (response.data.response) {
        setIsOtpSent(true);
        toast.success(response.data?.msg);
      } else {
        toast.error(response.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      password: "",
      otp: "",
      confirm_password: "",
    },
    validationSchema: RegisterFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await apiRequest.registerUser(values);
        if (response.data.response) {
          setLoading(false);
          toast.success(response.data?.msg);
          authManager.setAccessToken(response.data.token);
          dispatch(setProfileData(response.data.profile));
          formik.resetForm();
          navigate(HOME_ROUTE);
        } else {
          toast.error(response.data?.msg);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setValidationError(error.response.data.errors);
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div>
      <Helmet>
        <title>Register - Boundry Wala</title>
      </Helmet>
      <section className="bg-white">
        <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
          <section className="relative flex h-32 items-end bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
            <img
              alt="stationary"
              src={RegisterImage}
              className="absolute inset-0 h-full w-full object-cover opacity-80"
            />

            <div className="hidden lg:relative lg:block lg:p-12">
              <Link className="block text-white" href={HOME_ROUTE}>
                <span className="sr-only">Home</span>
              </Link>

              <h2 className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                Welcome to Shivgreen India
              </h2>

              <p className="mt-4 leading-relaxed text-white/90">
                Founded in the year 2015, Shiv Green Cement Products is the most
                trusted manufacturer and supplier from dealing in the
                revolutionary industrial walls. We aim to become the most
                trusted firm providing our customers with products that have
                high performance. Based in Patna, Bihar we manufacture and
                produce products like RCC Cover blocks, RCC poles, PSC poles,
                RCC slabs and much more. Our products are designed to appear as
                if they are made of crafted wood, natural stone or weathered
                brick and they do not get damaged by any collusion. Our products
                are inexpensive and easy to install.
              </p>
            </div>
          </section>
          <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
            <div className="max-w-xl lg:max-w-3xl">
              <div className="relative -mt-16 block lg:hidden">
                <div className="flex size-16 items-center justify-center rounded-full bg-white text-blue-600 sm:size-20">
                  <img src="/logo-white.png" />
                </div>

                <h1 className="mt-2 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                  Welcome to Variety Mega Store
                </h1>

                <p className="mt-4 leading-relaxed text-gray-500">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Eligendi nam dolorum aliquam, quibusdam aperiam voluptatum.
                </p>
              </div>

              <form
                onSubmit={formik.handleSubmit}
                className="mt-8 grid grid-cols-6 gap-6"
              >
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="FirstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <Input
                    type={"text"}
                    name={"name"}
                    placeholder={"Enter Your Name"}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    serverError={validationError.name}
                    touched={formik.touched.name}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="LastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <Input
                    type={"email"}
                    name={"email"}
                    placeholder={"Enter Your Email"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email}
                    serverError={validationError.email}
                    touched={formik.touched.email}
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="mobile"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile
                  </label>
                  <div className="relative">
                    <Input
                      type={"text"}
                      name={"mobile"}
                      disabled={isOtpSent}
                      placeholder={"Enter Your Mobile No."}
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.mobile}
                      serverError={validationError.mobile}
                      touched={formik.touched.mobile}
                    />
                    <div className="absolute top-3 right-3 text-base">
                      <div
                        onClick={handleOtp}
                        className="hover:text-accent-500 cursor-pointer"
                      >
                        {isOtpSent ? "Re-send OTP" : "Send OTP"}
                      </div>
                    </div>
                  </div>
                </div>
                {isOtpSent && (
                  <div className="col-span-6">
                    <label
                      htmlFor="Email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Enter OTP
                    </label>
                    <Input
                      type={"text"}
                      name={"otp"}
                      placeholder={"Enter 6 Digit OTP"}
                      value={formik.values.otp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.otp}
                      serverError={validationError.otp}
                      touched={formik.touched.otp}
                    />
                  </div>
                )}
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="Password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <Input
                    type={"password"}
                    name={"password"}
                    placeholder={"Enter Your Password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.password}
                    serverError={validationError.password}
                    touched={formik.touched.password}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="PasswordConfirmation"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password Confirmation
                  </label>

                  <Input
                    type={"password"}
                    name={"confirm_password"}
                    placeholder={"Confirm Your Password"}
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.confirm_password}
                    serverError={validationError.confirm_password}
                    touched={formik.touched.confirm_password}
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="MarketingAccept" className="flex gap-4">
                    <input
                      type="checkbox"
                      id="MarketingAccept"
                      name="marketing_accept"
                      className="size-5 rounded-md border-gray-200 bg-white shadow-sm"
                    />

                    <span className="text-sm text-gray-700">
                      I want to receive emails about events, product updates and
                      company announcements.
                    </span>
                  </label>
                </div>

                <div className="col-span-6">
                  <p className="text-sm text-gray-500">
                    By creating an account, you agree to our
                    <Link
                      to={TERMS_AND_CONDITION_ROUTE}
                      className="text-primary-500  mx-1"
                    >
                      terms and conditions
                    </Link>
                    and
                    <Link
                      href={PRIVACY_POLICY_ROUTE}
                      className="text-primary-500   mx-1"
                    >
                      privacy policy
                    </Link>
                    .
                  </p>
                </div>

                <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                  <button
                    type="submit"
                    disabled={loading}
                    className="inline-block shrink-0 rounded-md border border-accent-500 bg-accent-500 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-accent-500 focus:outline-none focus:ring active:text-accent-500"
                  >
                    Create an account
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
      </section>
    </div>
  );
};

export default Register;

import React from "react";
import { RiProductHuntLine } from "react-icons/ri";
import { SiRoamresearch, SiIntigriti } from "react-icons/si";
import { GrDeliver } from "react-icons/gr";
import { TbUsers } from "react-icons/tb";
import { BsEnvelopePaper } from "react-icons/bs";
import { ABOUT_ROUTE } from "../../utils/router";
import { Link } from "react-router-dom";
const Service = () => {
  return (
    <section>
      <div className="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
          <div className="mx-auto max-w-lg text-center lg:mx-0 ltr:lg:text-left rtl:lg:text-right">
            <h2 className="text-3xl font-bold sm:text-4xl">
              Building Boundaries. Building Trust.
            </h2>
            <p className="mt-4 text-gray-600">
              Feel free to customize this template to suit your company's unique
            </p>
            <p className="mt-4 text-gray-600">
              identity and goals. Would you like me to suggest any
              modifications or additions?
            </p>
            <Link
              to={ABOUT_ROUTE}
              className="mt-8 inline-block rounded bg-accent-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-accent-700 focus:outline-none focus:ring focus:ring-primary-400"
            >
              Know More
            </Link>
          </div>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
            <div
              className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
              href="#"
            >
              <span className="inline-block rounded-lg bg-gray-50 p-3">
                <RiProductHuntLine />
              </span>
              <h2 className="mt-2 font-bold">Product</h2>
              <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                High-quality products that ensure durability, safety, and
                aesthetics.
              </p>
            </div>
            <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
              <span className="inline-block rounded-lg bg-gray-50 p-3">
                <SiRoamresearch />
              </span>
              <h2 className="mt-2 font-bold">Innovation</h2>
              <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                Innovative designs that meet the evolving needs of our clients.
              </p>
            </div>
            <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
              <span className="inline-block rounded-lg bg-gray-50 p-3">
                <GrDeliver />
              </span>
              <h2 className="mt-2 font-bold">Fast Service</h2>
              <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                Timely delivery and installation to minimize project downtime.
              </p>
            </div>
            <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
              <span className="inline-block rounded-lg bg-gray-50 p-3">
                <TbUsers />
              </span>
              <h2 className="mt-2 font-bold">Team</h2>
              <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                Unparalleled customer service through our dedicated team.
              </p>
            </div>
            <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
              <span className="inline-block rounded-lg bg-gray-50 p-3">
                <BsEnvelopePaper />
              </span>
              <h2 className="mt-2 font-bold">Environmental</h2>
              <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                Sustainable practices that reduce environmental impact.
              </p>
            </div>
            <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
              <span className="inline-block rounded-lg bg-gray-50 p-3">
                <SiIntigriti />
              </span>
              <h2 className="mt-2 font-bold">Integrity</h2>
              <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                Transparency, honesty, and ethical business practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../home/categorySelection.css";
import "../home/slick.css";
import { CustomNextArrow, CustomPrevArrow } from "../product/ProductSlider";
import { getAssetUri } from "../../utils/helper";

const Feedback = ({ videos }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
      <div className=" py-3 rounded bg-white px-5">
        <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
          Customer Feedback
        </h3>
      </div>
      <Slider {...settings}>
        {videos.map((item, index) => {
          return (
            <>
              <div className="overflow-hidden relative py-7  px-3" key={index}>
                <div className="bg-white rounded-lg shadow-lg overflow-hidden  h-[250px]">
                  <a href={item.video_url} target="_blank" rel="noreferrer">
                    <img
                      src={getAssetUri("feedback", item.banner)}
                      alt="customer feedback"
                    />
                  </a>
                </div>
              </div>
            </>
          );
        })}
      </Slider>
    </div>
  );
};

export default Feedback;

import cn from "classnames";

const BannerCard = ({
  banner,
  className,
  variant = "default",
  effectActive = true,
  classNameInner,
}) => {
  return (
    <div className={cn("mx-auto", className)}>
      <a
        href={"/"}
        className={cn(
          "h-[185px] w-full group flex justify-center relative overflow-hidden",
          classNameInner
        )}
      >
        <div className="relative inline-block w-full overflow-hidden box-sizing">
          <div className="block w-full box-sizing">
            <svg
              className="block h-auto max-w-full"
              xmlns="http://www.w3.org/2000/svg"
              width={banner.image.width}
              height={banner.image.height}
              version="1.1"
            />
          </div>
          <img
            src={banner.image}
            alt={"grid banner"}
            width={0}
            height={0}
            sizes="100vw"
            className={cn(
              "absolute top-0 left-0 right-0 bottom-0 max-w-full max-h-full min-w-full min-h-full object-cover object-left-top",
              { "rounded-md": variant === "rounded" }
            )}
          />
        </div>
        {effectActive && (
          <div className="absolute top-0 block w-1/2 h-full transform -skew-x-12 -left-full z-5 bg-gradient-to-r from-transparent to-white opacity-30 group-hover:animate-shine" />
        )}
      </a>
    </div>
  );
};

export default BannerCard;

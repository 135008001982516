import React, { useEffect, useState } from "react";
import MyOrder from "../components/user/MyOrder";
import { Helmet } from "react-helmet";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
} from "@mui/material";
import { MdMenu } from "react-icons/md";
import Profile from "../components/user/Profile";
import { useDispatch, useSelector } from "react-redux";
import { getUserAddress } from "../redux/orderSlice";
import AddressCard from "../components/address/AddressCard";
import { FaRegTrashCan } from "react-icons/fa6";
import apiRequest from "../utils/apiRequest";
import toast from "react-hot-toast";
import authManager from "../utils/authManager";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../utils/router";
import { getHomeData } from "../redux/headerSlice";
import { LoadingButton } from "@mui/lab";
const MyAccount = () => {
  const [activeIndex, setActiveIndex] = useState("orders");
  const [otp, setOtp] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [deleteAccountPopup, setDeleteAccountPopup] = useState("");
  const { userAddress } = useSelector(({ orderRedux }) => orderRedux);
  const [validationError, setValidationError] = useState({ otp: "" });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterToggleHandler = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const closeFilter = () => {
    setIsFilterOpen(false);
  };
  const handleOtp = (e) => {
    setOtp(e.target.value);
  };
  const sendDeleteOtp = async () => {
    setIsLoading(true);
    try {
      const response = await apiRequest.sendDeleteOtp();
      if (response.data.response) {
        setDeleteAccountPopup("delete");
        toast.success(response.data?.msg);
      } else {
        toast.error(response.data?.msg);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidationError(error.response.data.errors);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const deleteAccount = async () => {
    setIsLoading(true);
    try {
      const response = await apiRequest.deleteAccount({ otp: otp });
      if (response.data.response) {
        dispatch(getHomeData());
        authManager.deleteAccessToken();
        navigate(HOME_ROUTE);
      } else {
        toast.error(response.data?.msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClosePopup = async () => {
    try {
      await apiRequest.deleteDeleteOtp();
    } catch (error) {
      console.log(error);
    }
    setDeleteAccountPopup("");
  };
  useEffect(() => {
    if (userAddress.length === 0) {
      dispatch(getUserAddress());
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>My Account - Boundry Wala</title>
      </Helmet>
      <div className="md:hidden flex items-center justify-end px-8 pt-6">
        <IconButton onClick={filterToggleHandler}>
          <MdMenu className="text-3xl" />
        </IconButton>
      </div>
      <Drawer open={isFilterOpen} onClose={closeFilter}>
        <div className="px-4 py-3 w-[280px]">
          <ul className="flex flex-col w-full">
            <li
              onClick={() => setActiveIndex("profile")}
              className="my-px flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              <span className="flex items-center justify-center text-lg text-gray-400">
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </span>
              <span className="ml-3">Profile</span>
            </li>
            <li
              onClick={() => setActiveIndex("orders")}
              className="my-px flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              <span className="flex items-center justify-center text-lg text-gray-400">
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                </svg>
              </span>
              <span className="ml-3">My Orders</span>
            </li>
            <li
              onClick={() => setActiveIndex("address")}
              className="my-px flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              <span className="flex items-center justify-center text-lg text-gray-400">
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
              </span>
              <span className="ml-3">Billing Address</span>
            </li>
            <li
              onClick={() => setDeleteAccountPopup("confirm")}
              className="my-px flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              <span className="flex items-center justify-center text-lg text-red-400">
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                </svg>
              </span>
              <span className="ml-3">Delete Account</span>
            </li>
          </ul>
        </div>
      </Drawer>

      <div className="min-h-screen flex">
        <div className="md:flex w-full max-w-xs p-4 hidden">
          <ul className="flex flex-col w-full">
            <li
              onClick={() => setActiveIndex("profile")}
              className="my-px flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              <span className="flex items-center justify-center text-lg text-gray-400">
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </span>
              <span className="ml-3">Profile</span>
            </li>
            <li
              onClick={() => setActiveIndex("orders")}
              className="my-px flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              <span className="flex items-center justify-center text-lg text-gray-400">
                <FaRegTrashCan className="h-6 w-6" />
              </span>
              <span className="ml-3">My Orders</span>
            </li>
            <li
              onClick={() => setActiveIndex("address")}
              className="my-px flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              <span className="flex items-center justify-center text-lg text-gray-400">
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
              </span>
              <span className="ml-3">Billing Address</span>
            </li>
            <li
              onClick={() => setDeleteAccountPopup("confirm")}
              className="my-px flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100 cursor-pointer"
            >
              <span className="flex items-center justify-center text-lg text-red-400">
                <FaRegTrashCan className="h-6 w-6" />
              </span>
              <span className="ml-3">Delete Account</span>
            </li>
          </ul>
        </div>
        <div className="w-full bg-white p-4 overflow-x-auto">
          {activeIndex === "orders" && <MyOrder />}
          {activeIndex === "profile" && <Profile />}
          {activeIndex === "address" && (
            <AddressCard
              className="grid mt-3 grid-cols-3 gap-3"
              address={userAddress}
            />
          )}
        </div>
      </div>
      <Dialog
        open={deleteAccountPopup === "confirm"}
        onClose={() => setDeleteAccountPopup("")}
      >
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAccountPopup("")} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            onClick={sendDeleteOtp}
            color="error"
            variant="outlined"
            loading={isLoading}
          >
            <span>Yes</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteAccountPopup === "delete"} onClose={handleClosePopup}>
        <DialogTitle>Enter OTP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <input
              onChange={handleOtp}
              value={otp}
              type="text"
              name="otp"
              className={`bg-gray-50 border border-gray-200 w-full rounded-md placeholder:text-base   px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-accent-500 focus:accent`}
              placeholder="Enter OTP"
            />
            <p className="text-base text-red-500 m-0 p-0">
              {validationError.otp}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            onClick={deleteAccount}
            color="error"
            variant="outlined"
            loading={isLoading}
          >
            <span>Delete</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default MyAccount;

import React, { useRef, useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import Slider from "react-slick";
import { formatAmountToINR, getAssetUri } from "../../utils/helper";
import { FaTruck, FaShieldAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { FaCartPlus } from "react-icons/fa";
import { addToCart } from "../../redux/headerSlice";
import {
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
const ProductDetail = ({ productDetail }) => {
  const sliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);
  const [mainSliderIndex, setMainSliderIndex] = useState(0);
  const dispatch = useDispatch();
  const images =
    productDetail && undefined !== productDetail.images
      ? productDetail.images.map((image) => image.image)
      : [];
  const mainSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => setMainSliderIndex(index),
  };
  const thumbnailSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
  };

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        product_id: productDetail.product.id,
        variation_id: productDetail.id,
        quantity: 1,
      })
    );
  };
  console.log(productDetail);
  const handleThumbnailClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };
  return (
    <div>
      <div className="container lg:max-w-7xl max-w-4xl mx-auto">
        <div className="grid gap-2 md:grid-cols-2 md:px-0 px-5 py-5">
          <div className="grid grid-cols-1">
            <div className="border-slate-400 productDetail productZoom p-0 m-0">
              <Slider ref={sliderRef} {...mainSliderSettings}>
                {images.map((image, index) => (
                  <div key={index} className="item">
                    <InnerImageZoom
                      zoomType="hover"
                      zoomScale={1}
                      src={getAssetUri("variations", image.image)}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <Slider
              className="flex justify-center zoomSlider"
              ref={thumbnailSliderRef}
              {...thumbnailSliderSettings}
            >
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`item ${
                    index === mainSliderIndex ? "active" : ""
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    style={{ height: "100px", width: "100px" }}
                    className="w-100"
                    src={getAssetUri("variations", image.image)}
                    alt={`Thumbnail ${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="w-full md:col-span-1">
            <div className="product-container bg-white p-8 rounded-lg">
              <h2 className="product-title text-heading font-semibold text-gray-800 mb-4">
                {productDetail.title}
              </h2>
              <Rating
                size="small"
                name="read-only"
                value={productDetail.rating}
                className="text-left"
                readOnly
              />
              <div className="price-section flex items-center justify-start gap-4">
                <p className="current-price text-3xl font-bold text-[#333]">
                  {formatAmountToINR(
                    productDetail.active_price.price,
                    productDetail.product.unit
                  )}
                </p>
                <p className="previous-price text-lg text-gray-500 line-through">
                  {formatAmountToINR(
                    productDetail.active_price.mrp,
                    productDetail.product.unit
                  )}
                </p>
              </div>

              <p className="description text-base text-gray-700 my-6">
                {productDetail.product.description}
              </p>
              <div className="feature-list mb-2">
                {productDetail.product &&
                  productDetail.product.features &&
                  productDetail.product.features.map((item, index) => {
                    return (
                      <li key={index} className="text-base">
                        {item.feature}
                      </li>
                    );
                  })}
              </div>
              {productDetail.product.deliver_between > 0 && (
                <div className="delivery-info flex items-center text-base text-gray-800 mb-2">
                  <FaTruck className="text-green-500 mr-2" />
                  <p>
                    {`Delivery within ${productDetail.product.deliver_between} business days`}
                  </p>
                </div>
              )}
              {productDetail.product.days_of_return > 0 && (
                <div className="return-policy flex items-center text-base text-gray-800 mb-5">
                  <FaShieldAlt className="text-red-500 mr-2" />
                  <p>
                    {`${productDetail.product.days_of_return} -day return
                          policy: We offer a ${productDetail.product.days_of_return} -day return policy for this product.`}
                  </p>
                </div>
              )}

              {productDetail.active_price.offer &&
                productDetail.active_price.offer === "50" && (
                  <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 my-5">
                    <p className="font-semibold">
                      Special Offer: Buy One, Get One Free!
                    </p>
                    <p>
                      For a limited time, buy one and get another one for free.
                      Hurry up!
                    </p>
                  </div>
                )}

              <TableContainer className="my-3" component={Paper}>
                <Table>
                  <TableBody>
                    {productDetail.values.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell className="font-semibold">
                          {item.value.attribute.attribute_name}
                        </TableCell>
                        <TableCell>{item.value.attribute_value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <button
                type="button"
                onClick={handleAddToCart}
                className="text-white  mt-4 md:mt-0 flex bg-gradient-to-r  bg-primary-500 hover:bg-accent-500 shadow-lg font-medium rounded-lg text-sm  w-full py-2 items-center justify-center me-2 mb-2 "
              >
                <FaCartPlus className="mr-2" />
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;

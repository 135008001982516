import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy & Policy - Boundry Wala</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Privacy Policy
            </strong>
          </h1>
        </div>
      </section>

      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 bg-white mt-5">
        <section>
          <div className="container ">
            <div className="grid grid-cols-1 gap-12 lg:grid-cols-1 lg:gap-16">
              <div className="lg:py-4">
                <p className="text-gray-600 text-justify mb-4">
                  Welcome to ShivGreen India. We respect your privacy and are
                  committed to protecting your personal information. This policy
                  explains how we collect, use, and safeguard your data when you
                  use our website or purchase our products.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  1. Information We Collect
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  We collect various types of information to provide and improve
                  our services:
                </p>
                <ul className="list-disc pl-6 text-gray-600 mb-4">
                  <li>
                    <strong>Personal Information:</strong> This includes your
                    name, email address, phone number, shipping address, and
                    payment details when you create an account or make a
                    purchase.
                  </li>
                  <li>
                    <strong>Non-Personal Information:</strong> We may collect
                    information about your device, browser, and browsing
                    activities to improve our website's performance and user
                    experience.
                  </li>
                  <li>
                    <strong>Cookies and Tracking Data:</strong> We use cookies
                    to track your browsing behavior, store preferences, and
                    analyze website traffic.
                  </li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  2. How We Use Your Information
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  The information we collect is used for the following purposes:
                </p>
                <ul className="list-disc pl-6 text-gray-600 mb-4">
                  <li>
                    Processing orders and delivering products to your address.
                  </li>
                  <li>
                    Providing customer support and responding to your inquiries.
                  </li>
                  <li>
                    Improving our website, services, and product offerings.
                  </li>
                  <li>
                    Sending promotional emails and special offers if you have
                    opted in.
                  </li>
                  <li>
                    Ensuring compliance with legal obligations and policies.
                  </li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  3. Sharing Your Information
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  We do not sell your personal information to third parties.
                  However, we may share your information with:
                </p>
                <ul className="list-disc pl-6 text-gray-600 mb-4">
                  <li>
                    Service providers who assist in fulfilling orders,
                    processing payments, or providing customer support.
                  </li>
                  <li>
                    Legal authorities, if required by law or to protect our
                    legal rights.
                  </li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  4. Security
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  We implement appropriate security measures to protect your
                  personal information from unauthorized access, alteration, or
                  disclosure.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  5. Changes to This Policy
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  We may update this Privacy Policy from time to time. We
                  encourage you to review it periodically for any changes.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";
const TermCondition = () => {
  return (
    <div>
      <Helmet>
        <title>Terms & Condition- Boundry Wala</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Terms & Condition
            </strong>
          </h1>
        </div>
      </section>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 bg-white mt-5">
        <section>
          <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-12 sm:py-12 lg:px-8 lg:py-16">
            <div className="grid grid-cols-1 gap-12 lg:grid-cols-1 lg:gap-16">
              <div className="lg:py-24">
                <h2 className="text-3xl font-bold sm:text-4xl">
                  Terms & Conditions
                </h2>

                <p className="text-gray-600 text-justify mb-4">
                  These Terms and Conditions govern your use of the ShivGreen
                  India website. By accessing or using our site, you agree to
                  these terms. If you disagree with any part of these terms,
                  please refrain from using our services.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  1. Intellectual Property
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  All content on this website, including text, graphics, logos,
                  images, and software, is the intellectual property of
                  ShivGreen India and protected by applicable copyright laws.
                  You may not reproduce, distribute, or create derivative works
                  from any content on this site without our explicit written
                  consent.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  2. User Responsibilities
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  As a user of this site, you agree to:
                </p>
                <ul className="list-disc pl-6 text-gray-600 mb-4">
                  <li>
                    Provide accurate, current, and complete information when
                    placing an order.
                  </li>
                  <li>
                    Maintain the confidentiality of your account information and
                    password.
                  </li>
                  <li>
                    Notify us immediately of any unauthorized use of your
                    account.
                  </li>
                  <li>
                    Use the website only for lawful purposes and in accordance
                    with these terms.
                  </li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  3. Product Information
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  We strive to provide accurate product descriptions and
                  pricing. However, we do not guarantee that the product
                  information is error-free, complete, or current. In the event
                  of an error, we reserve the right to correct it and adjust
                  prices or descriptions accordingly.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  4. Limitation of Liability
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  ShivGreen India is not liable for any direct, indirect,
                  incidental, or consequential damages arising from your use of
                  this site or the products purchased, except as required by
                  law. This includes but is not limited to damages for loss of
                  profits, goodwill, use, data, or other intangible losses.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  5. Governing Law
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  These Terms and Conditions shall be governed by and construed
                  in accordance with the laws of India. Any disputes arising
                  from these terms shall be resolved in the courts of [Your
                  City, Your State].
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  6. Modifications to Terms
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  We reserve the right to change these Terms and Conditions at
                  any time. Any changes will be posted on this page, and it is
                  your responsibility to review them regularly. Your continued
                  use of the site after any modifications constitutes acceptance
                  of the new terms.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">
                  7. Contact Us
                </h2>
                <p className="text-gray-600 text-justify mb-4">
                  If you have any questions or concerns regarding these Terms
                  and Conditions, please contact us at:
                </p>
                <p className="text-gray-600 text-justify mb-4">
                  Email: info@shivgreenindia.com
                  <br />
                  Phone: +91-7061777111
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TermCondition;

import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Register from "../pages/Register";
import About from "../pages/About";
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ReturnExchange from "../pages/ReturnExchange";
import TermCondition from "../pages/TermCondition";
import ProductDetail from "../pages/ProductDetail";
import Shop from "../pages/Shop";
import MyAccount from "../pages/MyAccount";
import WishList from "../pages/WishList";
import Checkout from "../pages/Checkout";
import Login from "../pages/Login";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/return-and-exchange" element={<ReturnExchange />} />
      <Route path="/terms-and-condition" element={<TermCondition />} />
      <Route path="/product-detail/:id/:title" element={<ProductDetail />} />
      <Route path="/shop/:id/:name" element={<Shop />} />

      <Route path="/login" element={<Login />} />
      <Route path="/wishlist" element={<WishList />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/my-account" element={<MyAccount />} />
    </Routes>
  );
}

import Service from "../components/home/Service";
import Hero from "../components/home/Hero";
import { FaProductHunt } from "react-icons/fa6";
import { FaUserClock } from "react-icons/fa";
import { SiRoamresearch, SiIntigriti } from "react-icons/si";
import ProductSlider from "../components/product/ProductSlider";
import { useSelector } from "react-redux";
import Feedback from "../components/home/Feedback";
function Home() {
  const { products, feedbacks } = useSelector(({ headerRedux }) => headerRedux);

  return (
    <>
      <Hero />
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
        <div className=" py-3 rounded bg-white px-5">
          <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
            Products
          </h3>
        </div>
        <ProductSlider
          heading="Products"
          text="Discover the ideal lens that perfectly suits your vision needs and lifestyle."
          products={products}
        />
      </div>
      <Service />
      <Feedback videos={feedbacks} />
      <div className=" py-3 rounded bg-white px-5">
        <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
          Core Values
        </h3>

        <div className="lg:grid-cols-4 grid-cols-1 grid gap-5  flex-wrap bg-white py-5">
          <div className="flex flex-col items-center justify-center gap-3">
            <FaProductHunt className="text-5xl text-primary-500" />
            <p className="uppercase">Quality</p>
            <p className="text-xs -[250px] text-center">
              Uncompromising commitment to excellence.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-3">
            <FaUserClock className="text-5xl text-primary-500" />
            <p className="uppercase">Customer Focus</p>
            <p className="text-xs max-w-[250px] text-center">
              Delivering tailored solutions to meet client needs.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-3">
            <SiIntigriti className="text-5xl text-primary-500" />
            <p className="uppercase">Innovation</p>
            <p className="text-xs max-w-[250px] text-center">
              Embracing cutting-edge technology and design.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-3">
            <SiRoamresearch className="text-5xl text-primary-500" />
            <p className="uppercase">Sustainability</p>
            <p className="text-xs max-w-[250px] text-center">
              Minimizing environmental footprint.
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 py-6  text-black bg-dutchwhite">
        <div className="mx-auto flex flex-col items-center justify-between gap-6 sm:flex-row max-w-screen-lg">
          <div className="sm:w-7/12">
            <div className="text-3xl font-bold">Our Vision</div>
            <p className="mt-3  text-sm text-slate-600">
              To be the leading provider of precast boundary solutions,
              recognized for our commitment to excellence, customer
              satisfaction, and community development.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;

import ReactDOM from "react-dom/client";
import "./css/index.css";
import AppRoutes from "./utils/routes";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({
  showSpinner: false,
  template: '<div className="bar" role="bar"></div>',
});

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#10852d",
      },
      secondary: {
        main: "#043494",
      },
    },
  });
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Toaster position="top-right" />
        <Header />
        <AppRoutes />
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

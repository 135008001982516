import React from "react";
import { HOME_ROUTE } from "../../utils/router";
import { Link } from "react-router-dom";
const CartEmpty = () => {
  return (
    <div>
      <div>
        <div className="bg-white p-6 md:mx-auto">
          <svg
            viewBox="0 0 24 24"
            className="text-gray-400 w-16 h-16 mx-auto my-6"
            fill="none"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 4v16m8-8H4"
            ></path>
          </svg>
          <div className="text-center">
            <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
              Your Cart is Empty
            </h3>
            <p className="text-gray-600 my-2">
              Looks like you haven't added anything to your cart yet.
            </p>
            <p>Start shopping and fill it up!</p>
            <div className="py-10 text-center">
              <Link
                href={HOME_ROUTE}
                className="px-12 bg-accent-500 hover:bg-accent-800 text-white font-semibold py-3 rounded-full inline-block"
              >
                START SHOPPING
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartEmpty;

import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";
const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Us - Boundry Wala</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Contact Us
            </strong>
          </h1>
        </div>
      </section>

      <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="lg:col-span-2 lg:py-12">
            <p className="max-w-xl text-lg">
              At the same time, the fact that we are wholly owned and totally
              independent from manufacturer and other group control gives you
              confidence that we will only recommend what is right for you.
            </p>

            <div className="mt-8">
              <a
                href="tel:7061777111"
                className="text-2xl font-bold text-pink-600"
              >
                +91-7061777111
              </a>

              <address className="mt-2 not-italic">
                <b>Kolkata Office: </b>Shiv Green India Infra Developers Pvt.
                Ltd. 1409, Maduradaha, Near Anandapur P.S., Kolkata - 700107
              </address>
              <address className="mt-2 not-italic">
                <b>Patna Office: </b>402, Surya Bageshwari, West Boring Canal
                Road, Patna - 800001
              </address>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;

import BannerCard from './BannerCard';
const BannerGrid = ({
  grid = 3,
  girdClassName,
  className = 'mb-3 xl:mb-6',
  images
}) => {
  return (
    <div className={className}>
      <div
        className={`grid grid-cols-1 sm:grid-cols-${grid} gap-2 ${girdClassName || 'md:gap-5'}`}
      >
        {images?.map((banner) => (
          <BannerCard
            key={`banner--key${banner.id}`}
            banner={banner}
            effectActive={true}
            className="w-full overflow-hidden rounded"
          />
        ))}
      </div>
    </div>
  );
};

export default BannerGrid;

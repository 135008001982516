import React from "react";
import AboutHome from "../assets/about/about-home.jpg";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";
const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Boundry Wala</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              About Us
            </strong>
          </h1>
        </div>
      </section>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 mt-5 bg-white">
        <section className="overflow-hidden  sm:grid sm:grid-cols-2 relative">
          <div className="mx-4 max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
            <p className=" text-gray-500 md:mt-4 ">
              Founded in the year 2015, Shiv Green Cement Products is the most
              trusted manufacturer and supplier from dealing in the
              revolutionary industrial walls. We aim to become the most trusted
              firm providing our customers with products that have high
              performance. Based in Patna, Bihar we manufacture and produce
              products like RCC Cover blocks, RCC poles, PSC poles, RCC slabs
              and much more. Our products are designed to appear as if they are
              made of crafted wood, natural stone or weathered brick and they do
              not get damaged by any collusion. Our products are inexpensive and
              easy to install.
            </p>
            <p className="hidden text-gray-500 md:mt-4 md:block">
              We have engineered our products professionals which interlock
              during the process of installation. We provide our customers with
              high-quality products and services to assure that there is
              efficient execution of your project. Our company has done intense
              research in creating these products which are economical and have
              great potential. We are committed to forming a long term
              relationship with our customers by serving them with quality
              products which meets their needs and specifications.
            </p>
          </div>
          <img
            alt="about"
            src={AboutHome}
            className="h-56 w-full object-cover sm:h-full"
          />
        </section>
      </div>
    </>
  );
};

export default About;
